import { getEnvironment } from 'shared/lib/getEnviroment';
import { LogoImage } from './LogoImage';
import * as Styled from './Logo.styled';

export const Logo = () => {
  const { isProd } = getEnvironment();

  const goToPro = () => {
    if (isProd) {
      location.href = 'https://pro.samoletgroup.ru';
    } else {
      location.href = location.href = 'https://pro-stage.samoletgroup.ru';
    }
  };

  return (
    <Styled.Container>
      <Styled.Button variant='link' onClick={goToPro}>
        <LogoImage />
      </Styled.Button>
    </Styled.Container>
  );
};
