export const getEnvironment = () => {
  const { host } = window.location;

  const isStage = host.includes('-stage');
  const isLocalhost = ['localhost', '127.0.0.1'].some((e) => host.includes(e));
  const isProd = !isStage && !isLocalhost;

  return {
    isStage,
    isLocalhost,
    isProd,
  };
};
