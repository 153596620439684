export const LogoImage = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='170'
    height='60'
    viewBox='0 0 170 60'
    fill='none'
  >
    <path
      d='M59.2607 22.4198H64.5956L68.8968 33.7897L73.1651 22.4198H78.313V37.5797H74.8913V25.9903L70.4348 37.5785H66.919L62.4614 26.0537V37.5797H59.2607V22.4198Z'
      fill='#007BFB'
    />
    <path
      d='M100.662 22.4198H112.434V37.5797H108.789V25.6149H103.99C103.958 27.7445 103.896 30.5958 103.834 32.1928C103.708 36.1705 102.54 37.5797 99.1243 37.5797H97.4922V34.3858H98.4023C99.9403 34.3858 100.442 33.6958 100.538 31.5345C100.632 29.2171 100.662 24.6128 100.662 22.4198Z'
      fill='#007BFB'
    />
    <path
      d='M131.052 22.4198H144.894V25.6149H139.778V37.5832H136.168V25.6149H131.052V22.4198Z'
      fill='#007BFB'
    />
    <path
      d='M49.8385 22.1082H48.5345C45.4032 22.1082 43.0315 24.0078 42.4871 26.8356L45.8124 27.3683C46.2675 26.0213 47.3128 25.1026 48.6344 25.0416C48.885 25.024 49.1364 25.024 49.387 25.0416C51.353 25.112 52.2408 26.053 52.2408 28.3751C48.5157 28.3751 45.9688 28.1991 44.0968 29.4311C42.5682 30.4379 41.9121 31.5537 41.9121 33.3173C41.9121 35.8705 43.9028 37.8934 47.1152 37.8934H47.5574C49.8267 37.8934 51.6587 37.0122 52.4042 35.948V37.5813H55.7565V28.4432C55.7589 24.9184 53.867 22.1082 49.8385 22.1082ZM47.7914 34.9354C46.3662 34.8943 45.5408 34.0706 45.5408 33.0298C45.5408 32.1674 45.93 31.7391 46.445 31.4458C46.9847 31.1313 47.6855 31.0234 48.7755 30.9847C49.8232 30.9471 50.6745 30.9013 52.3031 30.9013C52.3031 33.4828 50.6722 34.7887 48.6462 34.9272C48.3616 34.9454 48.0762 34.9481 47.7914 34.9354Z'
      fill='#007BFB'
    />
    <path
      d='M130.208 31.0248V29.605C130.208 24.7144 127.128 22.1835 123.153 22.1835H122.375C118.095 22.1835 115.365 25.0946 115.225 29.2049C115.205 29.8056 115.205 30.0567 115.225 30.8793C115.325 34.9685 118.197 37.7212 122.034 37.8209C122.74 37.8408 122.648 37.8397 123.31 37.8209C126.652 37.7282 129.181 35.8027 129.927 33.2882L126.695 32.7238C125.905 34.3008 124.399 34.9051 123.193 34.9649C122.92 34.9825 122.647 34.9825 122.375 34.9649C120.487 34.8875 118.796 33.4102 118.796 31.2653V31.0306L130.208 31.0248ZM122.312 25.0406C122.611 25.0207 122.912 25.0207 123.211 25.0406C124.798 25.1204 126.468 26.1494 126.63 28.1934H118.814C118.995 26.3689 120.465 25.1404 122.312 25.0394V25.0406Z'
      fill='#007BFB'
    />
    <path
      d='M96.4985 28.6616C96.4174 24.9937 93.4531 22.1835 89.534 22.1835H88.1135C84.1944 22.1835 81.2301 24.9995 81.1478 28.6816C81.1278 29.7047 81.1278 30.3008 81.1478 31.3228C81.2301 35.0119 84.1944 37.8209 88.1135 37.8209H89.534C93.4531 37.8209 96.4174 35.0119 96.4985 31.3228C96.5197 30.3008 96.5197 29.6848 96.4985 28.6616ZM92.9192 31.1351C92.8769 33.2776 91.3154 34.7314 89.2141 34.8382C88.9543 34.8593 88.6932 34.8593 88.4334 34.8382C86.3109 34.7314 84.7706 33.2776 84.7283 31.1351C84.7071 30.2445 84.7071 29.7388 84.7283 28.8482C84.7706 26.7279 86.3109 25.2729 88.4334 25.1673C88.6931 25.145 88.9543 25.145 89.2141 25.1673C91.3154 25.2729 92.8769 26.7279 92.9192 28.8693C92.9404 29.7599 92.9404 30.2445 92.9192 31.1351Z'
      fill='#007BFB'
    />
    <path
      d='M36.2707 32.231C35.8474 33.639 34.7151 34.6012 33.1935 34.8124C32.7862 34.8678 32.3733 34.8678 31.966 34.8124C30.0728 34.5484 28.7406 33.131 28.7006 31.1398C28.6795 30.2492 28.6795 29.7435 28.7006 28.8529C28.7406 26.8429 30.1399 25.3739 32.0683 25.1685C32.3808 25.1355 32.6959 25.1336 33.0089 25.1626C34.6281 25.3375 35.8474 26.309 36.2884 27.7851L39.8159 27.1046C39.0563 24.2298 36.4424 22.1835 33.0995 22.1835H32.0871C28.168 22.1835 25.2025 24.9937 25.1178 28.6816C25.1014 29.7047 25.1014 30.3008 25.1178 31.3228C25.1989 35.0119 28.1644 37.8209 32.0835 37.8209H33.0959C36.5059 37.8209 39.1422 35.6971 39.8394 32.7191L36.2707 32.231Z'
      fill='#007BFB'
    />
  </svg>
);
