import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { restApiMethods } from 'shared/api';
import { Profile } from 'entities/profile/model';
import * as constants from './constants';

const getProfile = (): Promise<Profile> => restApiMethods.get(constants.urls.profile);

export const useProfileQuery = () => {
  const queryFn = useCallback(() => getProfile(), []);

  const { data: profile, isLoading: profileLoading } = useQuery<Profile>({
    queryKey: [constants.queryKeys.getProfile],
    queryFn,
  });

  return {
    profile,
    profileLoading,
  };
};
