import React from 'react';

import { LayoutHeader } from './ui/layout-header';
import * as Styled from './MainLayout.styled';

export const MainLayout: React.FC = ({ children }) => (
  <Styled.Container>
    <LayoutHeader />
    {children}
  </Styled.Container>
);
