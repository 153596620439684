import { Tooltip } from '@10d/tend-ui/primitives/Tooltip';
import * as Styled from './ServiceButton.styled';

type ServiceButtonProps = {
  title: string;
  icon: React.ReactNode;
};

export const ServiceButton: React.FC<ServiceButtonProps> = ({ title, icon }) => (
  <Tooltip title={title}>
    <Styled.ServiceButton>{icon}</Styled.ServiceButton>
  </Tooltip>
);
