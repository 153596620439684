import { Logo, Profile } from './ui';
import * as Styled from './LayoutHeader.styled';

export const LayoutHeader = () => (
  <Styled.Container>
    <Styled.Wrapper>
      <Logo />
      <Profile />
    </Styled.Wrapper>
  </Styled.Container>
);
