import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import queryString from 'query-string';

type RouteProviderType = {
  children: React.ReactNode;
};

export const RouterProvider: React.FC<RouteProviderType> = ({ children }) => (
  <BrowserRouter>
    <QueryParamProvider
      adapter={ReactRouter5Adapter}
      options={{
        searchStringToObject: queryString.parse,
        objectToSearchString: queryString.stringify,
      }}
    >
      {children}
    </QueryParamProvider>
  </BrowserRouter>
);
