import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { TendUI } from '@10d/tend-ui/theme';
import { setAxiosAuthInterceptor } from 'samolet-oauth2';
import axios from 'axios';
import { GlobalStyle, theme } from 'app/theme';

import { AuthProvider, QueryProvider, RouterProvider } from 'app/providers';
import { sentryInit } from 'shared/lib/sentry';
import App from './app';

setAxiosAuthInterceptor(axios, {
  clientId: __SAMOLET_OAUTH2_PKCE_CLIENT_ID__,
});

sentryInit();

TendUI.init();

render(
  <ThemeProvider theme={theme}>
    <AuthProvider>
      <QueryProvider>
        <RouterProvider>
          <TendUI theme='samolet' lang='ru'>
            <GlobalStyle />
            <App />
          </TendUI>
        </RouterProvider>
      </QueryProvider>
    </AuthProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);
