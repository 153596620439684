import { ErrorBoundary } from '@sentry/react';
import { ComponentType } from 'react';
import { ErrorPage } from '../error-page';

export const withErrorBoundary = (WrappedComponent: ComponentType) => () =>
  (
    <ErrorBoundary fallback={<ErrorPage />}>
      <WrappedComponent />
    </ErrorBoundary>
  );
