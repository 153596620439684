import { useMemo } from 'react';
import { AnalyticsCounters } from '@10d/analytics-counters';
import { setUser, withProfiler } from '@sentry/react';
import { useProfileQuery } from 'entities/profile/api';
import { FullContainerSpinner } from 'shared/ui/full-container-spinner';
import { MainLayout } from './ui';
import { Router } from './routing/Router';
import { withErrorBoundary } from './ui/error-boundary';

const App = () => {
  const { profile, profileLoading } = useProfileQuery();

  const isLoading = useMemo(() => profileLoading, [profileLoading]);

  if (isLoading) {
    return <FullContainerSpinner />;
  }

  if (profile) {
    setUser({
      id: String(profile.id),
      username: profile.username,
      email: profile.email,
    });

    AnalyticsCounters.globalParams({
      user_id: profile.id,
      external_user: profile.role === 'partner' ? 1 : 0,
    });
  }

  return (
    <MainLayout>
      <Router />
    </MainLayout>
  );
};

export default withProfiler(withErrorBoundary(App));
