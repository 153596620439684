import React, { useEffect, useState } from 'react';

import { initAuth } from 'samolet-oauth2';
import { FullContainerSpinner } from 'shared/ui/full-container-spinner';

type AuthProviderType = ({ children }: { children: React.ReactNode }) => JSX.Element;

export const AuthProvider: AuthProviderType = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);

  const authorize = async () => {
    try {
      await initAuth({ clientId: __SAMOLET_OAUTH2_PKCE_CLIENT_ID__ });
      setIsAuthorized(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    authorize();
  }, []);

  if (loading || !isAuthorized) return <FullContainerSpinner />;

  return <>{children}</>;
};
