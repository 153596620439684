import styled from 'styled-components';
import { Popover as UIPopover } from '@10d/tend-ui/primitives/Popover';
import { Button as UiButton } from '@10d/tend-ui/primitives/Button';

export const Button: typeof UiButton = styled(UiButton)`
  padding: 0;
`;
export const Container = styled.div`
  position: relative;

  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  justify-content: end;
`;

export const Popover = styled(UIPopover)`
  .ant-popover-inner {
    padding: 8px;
  }
`;

export const PopoverContainer = styled.div`
  position: relative;

  display: grid;

  width: 256px;
  grid-gap: 2px;
`;

export const ProfileButton = styled.div`
  position: relative;

  display: flex;

  padding: 8px 16px;

  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  border-radius: 8px;
  align-items: center;

  svg {
    margin-right: 10px;

    font-size: 16px;
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.gray25};
  }
`;

export const UserContainer = styled.div`
  position: relative;

  display: flex;

  margin-left: 16px;

  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
`;

export const UserInitialsContainer = styled.div`
  position: relative;

  display: flex;
  overflow: hidden;

  width: 40px;
  height: 40px;
  margin-right: 12px;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue100};
  align-items: center;
  justify-content: center;
`;

export const UserInitials = styled.span`
  color: ${({ theme }) => theme.colors.blue600};

  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
`;

export const Username = styled.span`
  color: ${({ theme }) => theme.colors.blue600};

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;
