import styled from 'styled-components';

export const ServiceButton = styled.div`
  position: relative;

  display: flex;

  width: 40px;
  height: 40px;

  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  border-radius: 100px;
  align-items: center;
  justify-content: center;

  svg > path {
    fill: ${({ theme }) => theme.colors.gray650};
  }

  :hover {
    background-color: ${({ theme }) => theme.colors.gray100};
  }
`;
