import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  min-height: 100%;

  background-color: ${({ theme }) => theme.colors.gray0};
`;
