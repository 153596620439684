import { DefaultTheme } from 'styled-components';
import { colors } from '@10d/tend-ui/tokens/samolet';

const theme: DefaultTheme = {
  colors,
  shadows: {
    s: '0px 4px 4px -2px rgba(24, 39, 75, 0.04), 0px 2px 4px -2px rgba(24, 39, 75, 0.06)',
    m: '0px 14px 64px -4px rgba(24, 39, 75, 0.04), 0px 8px 22px -6px rgba(24, 39, 75, 0.04)',
    l: '0px 14px 64px -4px rgba(24, 39, 75, 0.06), 0px 8px 22px -6px rgba(24, 39, 75, 0.06)',
  },
};

export { theme };
