import { setAxiosAuthInterceptor } from 'samolet-oauth2';
import axios, { AxiosInstance } from 'axios';

class RestApiClient {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create();

    this.client.defaults.baseURL = '/api';

    setAxiosAuthInterceptor(this.client, {
      clientId: __SAMOLET_OAUTH2_PKCE_CLIENT_ID__,
    });
  }
}

export default RestApiClient;
