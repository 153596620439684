import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  display: flex;

  width: 100%;
  height: 60px;
  padding-right: 32px;

  background-color: ${({ theme }) => theme.colors.gray0};
  box-shadow: ${({ theme }) => theme.shadows.m};
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  position: relative;

  display: grid;
  gap: 60px;

  width: 100%;
  height: inherit;
  grid-template-columns: 170px 1fr auto;
  align-items: center;
`;
