import styled from 'styled-components';
import { Button as UiButton } from '@10d/tend-ui/primitives/Button/Button';

export const Container = styled.div`
  position: relative;

  display: flex;

  width: 170px;
  height: 60px;
  align-items: center;
`;

export const Button: typeof UiButton = styled(UiButton)`
  padding: 0;
`;
