import { logout } from 'samolet-oauth2';

import { Chat } from '@10d/tend-ui/icons/Chat';
import { Notification } from '@10d/tend-ui/icons/Notification';
import { PRO_LINK, SUPPORT_LINK } from 'app/constants/urls';
import { useProfileQuery } from 'entities/profile/api';
import * as Styled from './Profile.styled';
import { ServiceButton } from './ui/service-button';

export const Profile = () => {
  const { profile } = useProfileQuery();

  if (!profile) return null;

  const userInitials = (profile.firstName?.[0] || '') + (profile.lastName?.[0] || '');

  const goToSupport = () => {
    location.href = `${SUPPORT_LINK}/issues/my?tab=open`;
  };

  const goToNotification = () => {
    location.href = `${PRO_LINK}/notifications?page=1&type=IMPORTANT`;
  };

  return (
    <Styled.Container>
      <Styled.Button variant='link' onClick={goToNotification}>
        <ServiceButton title='Уведомления' icon={<Notification />} />
      </Styled.Button>
      <Styled.Button variant='link' onClick={goToSupport}>
        <ServiceButton title='Поддержка' icon={<Chat />} />
      </Styled.Button>
      <Styled.Popover
        trigger={['click']}
        placement='bottomRight'
        arrow={false}
        content={
          <Styled.PopoverContainer>
            <Styled.ProfileButton onClick={() => logout()}>
              {/* <Logout1Bulk /> */}
              Выйти
            </Styled.ProfileButton>
          </Styled.PopoverContainer>
        }
      >
        <Styled.UserContainer>
          <Styled.UserInitialsContainer>
            <Styled.UserInitials>{userInitials}</Styled.UserInitials>
          </Styled.UserInitialsContainer>
          <Styled.Username>
            {profile.firstName} {profile.lastName}
          </Styled.Username>
        </Styled.UserContainer>
      </Styled.Popover>
    </Styled.Container>
  );
};
